import { createRouter, createWebHistory } from 'vue-router'
import NewsList from '../views/newsList.vue'

const routes = [
    {
        path: '/',
        name: 'newsList',
        component: NewsList
    },
    {
        path: '/newsList',
        name: 'newsList',
        component: NewsList
    },
    {
        path: '/newsContent',
        name: 'newsContent',
        component: () => import('../views/newsContent.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router