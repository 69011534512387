<template>
    <div class="news-area">
        <view class="news-ul">
            <view v-bind:class="newsLi"  v-for="(news,key) in newsList" :key="key">
                <router-link :to="'/newsContent?newsId='+news.id"  v-bind:class="newsListA" target="_blank">
                <span class="news-time">{{convertToLocalTime(news.attributes.publishedAt).substr(11,5)}}</span> {{news.attributes.title}}
                </router-link>
            </view>
        </view>
    </div>
</template>

<script>
    import axiom from 'axios';
    import { useRoute } from "vue-router";
    import appConfig from '/public/config';
    import  {convertUTCTimeToLocalTime} from  "../assets/js/tools";

    export default {
        name: 'App',
        components: {
        },
        data () {
            return {
                newsLi: 'news-li',
                newsListA: 'news-list-a',
                newsLoad: true,
                newsList: []
            }
        },
        created () {
            const route = useRoute();
            let styleType = route.query.type;
            if(styleType == 'black'){
                let bodyStyle = document.body.style;
                bodyStyle.background = '#070707';
                this.newsLi = 'news-black-li';
                this.newsListA = 'news-list-black-a';
            }
            this.getNewsList();
        },
        methods: {
            getNewsList () {
                const that = this;
                axiom.get(appConfig.app.newsUrl+'/api/articles?pagination[page]=1&pagination[pageSize]=60&sort=publishedAt:desc')
                    .then(function (res) {
                        console.log(res);
                        if (res.data.data == null) {
                            that.newsLoad = false;
                        }else{
                            that.newsList = res.data.data;
                            console.log(that.newsList);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            convertToLocalTime($timeString){
                return   convertUTCTimeToLocalTime($timeString);
            }
        }

    }
</script>

<style scoped>
@import "../assets/css/newsList.css";
</style>
