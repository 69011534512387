export function convertUTCTimeToLocalTime(UTCDateString){
    if(!UTCDateString){
        return '-';
    }
    function formatFunc(str) {    //格式化显示
        return str > 9 ? str : '0' + str
    }
    let date2 = new Date(UTCDateString);
    let year = date2.getFullYear();
    let mon = formatFunc(date2.getMonth() + 1);
    let day = formatFunc(date2.getDate());
    let hour = date2.getHours();
    hour = formatFunc(hour);
    let min = formatFunc(date2.getMinutes());
    let dateStr = year+'-'+mon+'-'+day+' '+hour+':'+min;
    return dateStr;
}

